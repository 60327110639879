import { Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AmountDto, AvailableFilterRecord, CurrencyEnum, DynamicOrder, FileResponse, FilterTypeEnum, RelatedEntityEnum } from '../nswag.api';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FgPurchaseInvoiceLine } from 'src/app/purchases/services/purchase-invoice.service';
import { FgSalesRequestLine } from 'src/app/sales/services/sale-request.service';
import { FgSalesOrderLine } from 'src/app/sales/services/sale-order.service';
import { FgSalesOfferLine } from 'src/app/sales/services/sale-offer.service';
import { FgSalesInvoiceLine } from 'src/app/sales/services/sale-invoice.service';
import { FgPurchaseRequestLine } from 'src/app/purchases/services/purchase-request.service';
import { FgPurchaseOfferLine } from 'src/app/purchases/services/purchase-offer.service';
import { FgPurchaseOrderLine } from 'src/app/purchases/services/purchase-order.service';
import { FilterModel } from '../interfaces/filter-model';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { AfterSaveRedirectEnum } from '../signalr.api';
import { ConfigurationsStore } from 'src/app/state/configurations-store';
import { ItemsStore } from 'src/app/state/items-store';
import { UserOptionStore } from 'src/app/state/user-options.store';
import { InventoryStore } from 'src/app/state/inventory-store';
import { HrStore } from 'src/app/state/hr-store';
import { DevPosStore } from 'src/app/state/devpos.store';
import Decimal from 'decimal.js';
import { AllowIn } from 'ng-keyboard-shortcuts';

const DECIMAL_PLACES = 10;
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  assetsUrl: string;
  activeFiltersNumber: Subject<number> = new Subject<number>();

  creditValue = new Subject<any>();

  constructor(
    private router: Router,
    private toast: ToastrService,
    private store: Store,
    private injector: Injector,
  ) {
    this.assetsUrl = environment.assetsUrl;
  }

  // getValue(): Observable<any> {
  //   return this.creditValue.asObservable();
  // }
  //
  // sendValue(val: any) {
  //   this.creditValue.next(val);
  // }

  clearCacheFunction() {
    const configurationsStore = this.injector.get(ConfigurationsStore);
    const itemsStore = this.injector.get(ItemsStore);
    const userOptionsStore = this.injector.get(UserOptionStore);
    const devPosStore = this.injector.get(DevPosStore);
    const inventoryStore = this.injector.get(InventoryStore);
    const hrStore = this.injector.get(HrStore);
    configurationsStore.clearConfigurations();
    userOptionsStore.clearUserOptions();
    itemsStore.clearItems();
    devPosStore.clearDevPosCache();
    hrStore.clearHrCache();
    inventoryStore.clearInventoryCache();
  }

  encodeParamsToBase64(filtersList: FilterModel[], ordersList?: DynamicOrder[], isOrOperator: boolean = false): any {
    let filtersValue: {
      availableFilters?: AvailableFilterRecord;
      formValue: any;
    }[] = [];
    const orders = ordersList?.length! > 0 ? Buffer.from(JSON.stringify(ordersList)).toString('base64') : null;
    if (filtersList.length > 0) {
      filtersList.forEach(element => {
        if (element.form.valid) {
          filtersValue.push({
            availableFilters: element.availableFilterRecord,
            formValue: this.getFormValue(element),
          });
        }
      });
      if (filtersValue.length > 0) {
        let objToEncode = {
          filtersValue: filtersValue,
          isOrOperator: isOrOperator,
        };
        let objJsonB64 = Buffer.from(JSON.stringify(objToEncode)).toString('base64');
        return { filter: objJsonB64, order: orders };
      }
    }
    return orders ? { order: orders } : undefined;
  }

  generateForm(filter: AvailableFilterRecord | undefined) {
    let form: FormGroup = new FormGroup({
      filterName: new FormControl(filter?.filterName, [Validators.required]),
      operator: new FormControl(filter?.operators![0], Validators.required),
      value: new FormControl(filter?.filterNameTranslated, Validators.required),
    });
    return form;
  }

  getFormValue(element: FilterModel) {
    if (element.availableFilterRecord!.filterType === FilterTypeEnum.Date) {
      return {
        filterName: element.form.value.filterName,
        operator: element.form.value.operator,
        value: moment(element.form.value?.value).format('YYYY-MM-DD'),
      };
    } else {
      return element.form.value;
    }
  }

  initAmountDto(
    exch: number | null | undefined,
    currency: CurrencyEnum | null,
    amount: number | null | undefined,
    valuesAreInLocalCurrency?: boolean,
  ): AmountDto {
    if (!valuesAreInLocalCurrency) {
      return new AmountDto({
        amountCCY: amount!,
        currency: currency ?? CurrencyEnum.ALL,
        exchangeRate: exch ?? 1,
        amountLCY: amount! * exch!,
      });
    } else {
      return new AmountDto({
        amountCCY: amount! / exch!,
        currency: currency ?? CurrencyEnum.ALL,
        exchangeRate: exch ?? 1,
        amountLCY: amount!,
      });
    }
  }
  clearValueAndValidatorsForQuantityAndUnitOfMeasureCode(
    fgLine:
      | FormGroup<FgSalesRequestLine>
      | FormGroup<FgSalesOrderLine>
      | FormGroup<FgSalesOfferLine>
      | FormGroup<FgSalesInvoiceLine>
      | FormGroup<FgPurchaseRequestLine>
      | FormGroup<FgPurchaseOfferLine>
      | FormGroup<FgPurchaseOrderLine>
      | FormGroup<FgPurchaseInvoiceLine>,
  ) {
    // fgLine.controls.quantity.patchValue(null);
    fgLine.controls.unitOfMeasureCode.patchValue(null);
    fgLine.controls.unitOfMeasureName.patchValue(null);
    // fgLine.controls.quantity.clearValidators();
    fgLine.controls.unitOfMeasureCode.clearValidators();
    fgLine.controls.unitOfMeasureName.clearValidators();
    fgLine.controls.unitOfMeasureCode.updateValueAndValidity();
    fgLine.controls.unitOfMeasureName.updateValueAndValidity();
    // fgLine.controls.quantity.updateValueAndValidity();
  }
  addValidatorsForQuantityAndUnitOfMeasureCode(
    fgLine:
      | FormGroup<FgSalesRequestLine>
      | FormGroup<FgSalesOrderLine>
      | FormGroup<FgSalesOfferLine>
      | FormGroup<FgSalesInvoiceLine>
      | FormGroup<FgPurchaseRequestLine>
      | FormGroup<FgPurchaseOfferLine>
      | FormGroup<FgPurchaseOrderLine>
      | FormGroup<FgPurchaseInvoiceLine>,
  ) {
    fgLine.controls.quantity.addValidators(Validators.required);
    fgLine.controls.unitOfMeasureCode.addValidators(Validators.required);
    fgLine.controls.unitOfMeasureName.addValidators(Validators.required);
    fgLine.controls.unitOfMeasureCode.updateValueAndValidity();
    fgLine.controls.unitOfMeasureName.updateValueAndValidity();
    fgLine.controls.quantity.updateValueAndValidity();
  }

  openUrlNewWindow(
    path: string,
    no: string | undefined,
    isViewOnlyMode: boolean = true,
    width = window.screen.width * 0.95,
    height = window.screen.height * 0.9,
  ) {
    const top = window.screen.height > height ? (window.screen.height - height) / 2 : 10;
    const left = window.screen.width > width ? (window.screen.width - width) / 2 : 10;
    const queryParams = {
      ...(!isViewOnlyMode ? undefined : { prevent_click: true }),
      no,
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${path}`], {
        queryParams: queryParams,
      }),
    );
    window.open(
      url,
      undefined,
      `height=${height},width=${width},popup=yes,top=${top},left=${left},noopener=yes,location=no,menubar=no,toolbar=no,status=no,titlebar=no`,
    );
  }

  downloadBlob(response: FileResponse, name: string | undefined = undefined) {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    const FileName = name + day + '-' + month + '-' + year;
    const url = window.URL.createObjectURL(response.data);
    const anchor = document.createElement('a');
    anchor.download =
      FileName === undefined ? 'file' + this.parseBlobToExtension(response.data) : FileName + this.parseBlobToExtension(response.data);
    anchor.href = url;
    anchor.click();
  }

  decodeBase64(base64String: string) {
    const jsonString = Buffer.from(base64String, 'base64').toString();
    return JSON.parse(jsonString);
  }

  documentClick(orderType: RelatedEntityEnum | undefined, orderNo: string | undefined, isRedirectPage?: boolean) {
    let link: string = '';
    let queryParams = { no: orderNo };

    switch (orderType) {
      case RelatedEntityEnum.PayrollHeaderEntity:
        link = `/human-resources/payroll/view`;
        break;
      case RelatedEntityEnum.PurchaseRequestHeaderEntity:
        link = `/purchases/purchase-request/view`;
        break;
      case RelatedEntityEnum.PurchaseOfferHeaderEntity:
        link = `/purchases/purchase-offer/view`;
        break;
      case RelatedEntityEnum.InvQntyPriceAdjHeaderEntity:
        link = `/inventory/inventory-adjustment/view`;
        break;
      case RelatedEntityEnum.InvCostReEvalHeaderEntity:
        link = `/inventory/inventory-revaluation/view`;
        break;
      case RelatedEntityEnum.SalesInvoiceHeaderEntity:
        link = `/sales/sale-invoice/view`;
        break;
      case RelatedEntityEnum.TransferHeaderEntity:
        link = `/inventory/transfers/view`;
        break;
      case RelatedEntityEnum.GeneralJournalHeaderEntity:
        link = `/financial-management/general-journal/view`;
        break;
      case RelatedEntityEnum.TransferReceiptHeaderEntity:
        link = `/inventory/transferreceipts/view`;
        break;
      case RelatedEntityEnum.TransferShipmentHeaderEntity:
        link = `/inventory/transfershipments/view`;
        break;
      case RelatedEntityEnum.ShippingAgentEntity:
        link = `/inventory/shipping-agent`;
        break;
      case RelatedEntityEnum.VehicleEntity:
        link = `/inventory/vehicle`;
        break;
      case RelatedEntityEnum.BankDepositEntity:
        link = `/financial-management/bankdeposit/view`;
        break;
      case RelatedEntityEnum.BankWithdrawEntity:
        link = `/financial-management/bankwithdraw/view`;
        break;
      case RelatedEntityEnum.CashReceiptEntity:
        link = `/financial-management/cashreceipts/view`;
        break;
      case RelatedEntityEnum.CashPaymentEntity:
        link = `/financial-management/cashpayments/view`;
        break;
      case RelatedEntityEnum.ItemEntity:
        link = `/items/view`;
        break;
      case RelatedEntityEnum.SalesRequestHeaderEntity:
        link = `/sales/sale-request/view`;
        break;
      case RelatedEntityEnum.PurchaseInvoiceHeaderEntity:
        link = `/purchases/purchase-invoice/view`;
        break;
      case RelatedEntityEnum.CustomClearanceEntity:
        link = `/purchases/custom-clearance/view`;
        break;
      case RelatedEntityEnum.InitialYearEntryHeaderEntity:
        link = `/financial-management/initial-year-entry/view`;
        break;
      case RelatedEntityEnum.DepreciationHeaderEntity:
        link = `/inventory/amortization/view`;
        break;
      case RelatedEntityEnum.DevPosSalesInvoiceEntity:
        this.goToDevposInvoice(orderNo);
        return;
      case RelatedEntityEnum.SalesOrderHeaderEntity:
        link = `/sales/sale-order/view`;
        break;
      case RelatedEntityEnum.FixedAssetAdditionHeaderEntity:
        link = `/inventory/asset-addition/view`;
        break;
      case RelatedEntityEnum.CustomClearanceExportEntity:
        link = `/sales/custom-clearance-export/view`;
        break;
      case RelatedEntityEnum.EndingYearHeaderEntity:
        link = `/financial-management/ending-year`;
        break;
      case RelatedEntityEnum.ExpenseSharingHeaderEntity:
        link = `/financial-management/expense-sharing/view`;
        break;
      case RelatedEntityEnum.SalesOfferHeaderEntity:
        link = `/sales/sale-offer/view`;
        break;
      case RelatedEntityEnum.PurchaseOrderHeaderEntity:
        link = `/purchases/purchase-order/view`;
        break;
      default:
        this.toast.error(`Nuk eshte implementuar route per tipin me id ${orderType}. Kontaktoni supportin per me shume.`);
        return;
    }

    if (link !== '') {
      if (isRedirectPage) {
        this.router.navigate([link], { queryParams });
      } else {
        this.openUrlNewWindow(`${link}`, orderNo);
      }
    }
  }

  documentNavigate(document: RelatedEntityEnum | undefined) {
    let link: string = '';
    switch (document) {
      case RelatedEntityEnum.GeneralJournalHeaderEntity:
        link = `/financial-management/general-journal/add`;
        break;
      case RelatedEntityEnum.TransferReceiptHeaderEntity:
        link = '/inventory/transferreceipts/add';
        break;
      case RelatedEntityEnum.TransferShipmentHeaderEntity:
        link = '/inventory/transfershipments/add';
        break;
      case RelatedEntityEnum.CashReceiptEntity:
        link = '/financial-management/cashreceipts/add';
        break;
      case RelatedEntityEnum.CashPaymentEntity:
        link = '/financial-management/cashpayments/add';
        break;
      case RelatedEntityEnum.BankDepositEntity:
        link = '/financial-management/bankdeposit/add';
        break;
      case RelatedEntityEnum.BankWithdrawEntity:
        link = '/financial-management/bankwithdraw/add';
        break;
      case RelatedEntityEnum.SalesInvoiceHeaderEntity:
        link = '/sales/sale-invoice/add';
        break;
      case RelatedEntityEnum.PurchaseInvoiceHeaderEntity:
        link = '/purchases/purchase-invoice/add';
        break;
    }
    if (link !== '') this.router.navigate([link]);
  }

  goToDevposInvoice(devposInvoiceNumber?: string) {
    let form: FormGroup = new FormGroup({});
    let filter: AvailableFilterRecord | undefined = new AvailableFilterRecord();
    let filterModel: FilterModel[] = [];
    if (devposInvoiceNumber) {
      filter.filterName = 'Data.InvoiceNumber';
      filter.operators = [6];
      filter.filterNameTranslated = devposInvoiceNumber;
      form = this.generateForm(filter);
      filterModel.push({
        form: form,
        availableFilterRecord: new AvailableFilterRecord({
          filterName: 'Data.InvoiceNumber',
          filterType: 1,
        }),
        selectData: undefined,
      });
    }
    this.router.navigate(['/integrations/devpos/sales-invoices'], {
      queryParams: this.encodeParamsToBase64(filterModel),
    });
  }
  roundToTen(value?: number) {
    if (!value) return 0;
    const decimalValue = new Decimal(value);
    return +decimalValue.toDecimalPlaces(DECIMAL_PLACES);
  }
  add(x: number, y: number) {
    const decimalX = new Decimal(this.roundToTen(x));
    const decimalY = new Decimal(this.roundToTen(y));
    const d = decimalX.plus(decimalY);
    return +d.toDecimalPlaces(DECIMAL_PLACES);
  }

  multiply(x: number, y: number) {
    const decimalX = new Decimal(this.roundToTen(x));
    const decimalY = new Decimal(this.roundToTen(y));
    const d = decimalX.times(decimalY);
    return +d.toDecimalPlaces(DECIMAL_PLACES);
  }
  lineCalculationAndRound(
    fgLine:
      | FormGroup<FgSalesOrderLine>
      | FormGroup<FgSalesOfferLine>
      | FormGroup<FgSalesInvoiceLine>
      | FormGroup<FgPurchaseOfferLine>
      | FormGroup<FgPurchaseOrderLine>
      | FormGroup<FgPurchaseInvoiceLine>,
    isReverseInvoice: boolean = false,
  ) {
    const vatRate: number = fgLine.controls.vatPercentage.getRawValue() ?? 0;
    const productPrice = fgLine.controls.unitPriceValueCCY?.getRawValue()!;
    const discountPercentage = Number(fgLine.controls.unitDiscountValuePercentage?.getRawValue()!);
    const discountPerUnit = Number(fgLine.controls.unitDiscountValueCCY?.getRawValue()!);
    // // meqe llogarite nuk kane quantity => na duhet te percaktojme per efekt kalkulimesh sasine 1 ose -1 => nese tvsh-ja eshte negative dmth eshte reverse
    // isReverseInvoice = !!(
    //   fgLine.controls.amountValueCCY?.getRawValue() &&
    //   fgLine.controls.amountValueCCY?.getRawValue()! < 0
    // );
    const quantity = fgLine.controls.quantity?.getRawValue() ?? 1;
    (fgLine.controls.quantity?.getRawValue() ? fgLine.controls.quantity?.getRawValue() : isReverseInvoice ? -1 : 1) ?? 1;

    let discountAmount = discountPerUnit;
    if (discountPercentage && discountPercentage !== 0) {
      discountAmount = this.roundToTen((discountPercentage / 100) * productPrice);
    }

    const priceWithDiscount = this.roundToTen(productPrice - discountAmount);
    const totalWithoutVatAfterDiscount = this.roundToTen(quantity * priceWithDiscount);

    const totalVatAfterDiscount = this.roundToTen((totalWithoutVatAfterDiscount * vatRate) / 100);
    const totalWithVatAfterDiscount = this.roundToTen(totalWithoutVatAfterDiscount + totalVatAfterDiscount);
    fgLine.controls.amountValueCCY?.patchValue(totalWithVatAfterDiscount ?? 0);
    fgLine.controls.vatValueCCY?.patchValue(totalVatAfterDiscount ?? 0);
    fgLine.controls.amountWithoutVatValueCCY?.patchValue(totalWithoutVatAfterDiscount ?? 0);

    fgLine.controls.discountValueCCY?.patchValue(this.roundToTen(Math.abs(discountAmount * quantity)));
  }
  redirectAfterSave(
    afterSaveRedirect: AfterSaveRedirectEnum,
    viewPath: string | undefined,
    listPath: string | undefined,
    no: string | undefined = undefined,
  ) {
    switch (afterSaveRedirect) {
      case AfterSaveRedirectEnum.Create:
        window.location.reload();
        break;
      case AfterSaveRedirectEnum.View:
        this.router.navigate([viewPath], { queryParams: { no: no } });
        break;
      default:
        this.router.navigate([listPath]);
        break;
    }
  }
  addRowShortCuts(addRow: () => void) {
    return [
      {
        key: ['cmd + enter'],
        label: 'Add Row',
        description: 'Cmd + Enter',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: e => addRow(),
        preventDefault: true,
      },
      {
        key: ['F1'],
        label: 'Add Row',
        description: 'F1',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: e => addRow(),
        preventDefault: true,
      },
    ];
  }

  private parseBlobToExtension(blob: Blob): string {
    const mimeType = blob.type;
    const extensionMap: { [key: string]: string } = {
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
      'image/tiff': '.tiff',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'application/pdf': '.pdf',
      // Add more MIME types and extensions as needed
    };

    return extensionMap[mimeType] || '';
  }
}
