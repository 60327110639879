import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject, Injector } from '@angular/core';
import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { GetStateData, IStateData } from '../shared/interfaces/state/state';
import {
  DevPosConfigDto,
  HttpClientDevPosCashReceipt,
  HttpClientDevPosConfig,
  HttpClientDevPosDynamic,
  HttpClientDevPosPurchaseInvoice,
  HttpClientDevPosSaleOrder,
  HttpClientDevPosSalesInvoice,
  ImportTableTypeEnum,
  IntegrationStatisticsDto,
} from '../shared/devpos.api';
import moment from 'moment/moment';
import { EXPIRE_API_SECONDS } from '../shared/constants/api-priority.seconds';
import { firstValueFrom, map, take, tap } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';
import { HttpClientAlpha } from '../shared/nswag.api';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationsStore } from './configurations-store';
import { TranslateService } from '@ngx-translate/core';

export const DevPosTables: ImportTableTypeEnum[] = [
  ImportTableTypeEnum.DevPosSalesInvoice,
  ImportTableTypeEnum.DevPosItems,
  ImportTableTypeEnum.DevPosCustomer,
  ImportTableTypeEnum.DevPosVendor,
  ImportTableTypeEnum.DevPosLocation,
  ImportTableTypeEnum.DevPosCashRegister,
  ImportTableTypeEnum.DevPosItemCategory,
  ImportTableTypeEnum.DevPosShippingAgent,
  ImportTableTypeEnum.DevPosUnitOfMeasure,
  ImportTableTypeEnum.DevPosPointOfSale,
  ImportTableTypeEnum.DevPosPurchaseInvoice,
];

export type DEVPOS_TYPE_STATE = {
  tableType: ImportTableTypeEnum;
  data: IStateData<IntegrationStatisticsDto>;
};
type DEVPOS_STATE = {
  devPosStats: DEVPOS_TYPE_STATE[];
  configs: IStateData<DevPosConfigDto[]>;
  alphaStatistics: IStateData<IntegrationStatisticsDto[]>;

  sync: {
    ids: IStateData<{ table: ImportTableTypeEnum; rowId: number }>[];
    all: IStateData<{ table: ImportTableTypeEnum }>[];
  };
  post: {
    ids: IStateData<{ table: ImportTableTypeEnum; rowId: number }>[];
  };

  loadedIds: {
    ImportTableType: ImportTableTypeEnum;
    RowId: number[];
  }[];
};

const INITIAL_DEVPOS_STATE: DEVPOS_STATE = {
  devPosStats: [],
  configs: {
    data: [],
    loading: false,
    lastUpdatedDate: undefined,
  },
  alphaStatistics: {
    data: [],
    loading: false,
    lastUpdatedDate: undefined,
  },
  sync: {
    ids: [],
    all: [],
  },
  post: {
    ids: [],
  },
  loadedIds: [],
};

export const DevPosStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_DEVPOS_STATE),
  withComputed(state => ({
    // validDevPosStatistics: computed(() => state.devPosStats().map(x => GetStateData(x.data, moment(), EXPIRE_API_SECONDS.INTEGRATION, false))),
    validConfigs: computed(() => GetStateData(state.configs(), moment(), EXPIRE_API_SECONDS.INTEGRATION, false) ?? []),
    validAlphaStatistics: computed(() => GetStateData(state.alphaStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, false)),
  })),
  withMethods(
    (
      store,
      httpClientDevPosDynamic = inject(HttpClientDevPosDynamic),
      httpClientDevPosSiDynamic = inject(HttpClientDevPosSalesInvoice),
      httpClientDevPosSaleOrderDynamic = inject(HttpClientDevPosSaleOrder),
      httpClientDevPosCashReceiptDynamic = inject(HttpClientDevPosCashReceipt),
      httpClientDevPosPurchaseInvoice = inject(HttpClientDevPosPurchaseInvoice),
      httpClientAlpha = inject(HttpClientAlpha),
      httpClientDevPosConfig = inject(HttpClientDevPosConfig),
      toast = inject(ToastrService),
      injector = inject(Injector),
      configurationStore = inject(ConfigurationsStore),
    ) => ({
      loadDevPosConfigs: async (force_skip: boolean = false) => {
        patchState(store, { configs: { ...store.configs(), loading: true } });

        if (!force_skip) {
          const existingData = GetStateData(store.configs(), moment(), EXPIRE_API_SECONDS.INTEGRATION, configurationStore.skipCache().data);
          if (existingData) {
            patchState(store, { configs: { ...store.configs(), loading: false } });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientDevPosConfig.configRead().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            configs: {
              data: [],
              loading: false,
              lastUpdatedDate: undefined,
            },
          });
          return;
        }

        patchState(store, {
          configs: {
            data: newData.data,
            loading: false,
            lastUpdatedDate: moment(),
          },
        });
      },

      loadDevPosStat: async (tableType: ImportTableTypeEnum, force_skip: boolean = false) => {
        const index = store.devPosStats().findIndex(x => x.tableType === tableType);
        if (index != -1)
          patchState(store, {
            devPosStats: [
              ...store.devPosStats().slice(0, index),
              { tableType: tableType, data: { ...store.devPosStats()[index].data, loading: true } },
              ...store.devPosStats().slice(index + 1),
            ],
          });
        else
          patchState(store, {
            devPosStats: [...store.devPosStats(), { tableType: tableType, data: { data: undefined, loading: true, lastUpdatedDate: undefined } }],
          });

        if (!force_skip && index !== -1) {
          const dt = store.devPosStats()[index].data;
          const existingData = GetStateData(dt, moment(), EXPIRE_API_SECONDS.INTEGRATION, configurationStore.skipCache().data);
          if (existingData) {
            patchState(store, {
              devPosStats: [
                ...store.devPosStats().slice(0, index),
                {
                  tableType: tableType,
                  data: {
                    ...store.devPosStats()[index].data,
                    loading: false,
                  },
                },
                ...store.devPosStats().slice(index + 1),
              ],
            });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientDevPosDynamic.devPostStatistics(tableType).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            devPosStats: [
              ...store.devPosStats().slice(0, index),
              { tableType: tableType, data: { data: undefined, lastUpdatedDate: moment(), loading: false } },
              ...store.devPosStats().slice(index + 1),
            ],
          });

          return;
        }

        if (index !== -1) {
          patchState(store, {
            ...store.configs,
            devPosStats: [
              ...store.devPosStats().slice(0, index),
              { tableType: tableType, data: { data: newData.data, lastUpdatedDate: moment(), loading: false } },
              ...store.devPosStats().slice(index + 1),
            ],
          });
        } else {
          patchState(store, {
            ...store.configs,
            devPosStats: [...store.devPosStats(), { tableType: tableType, data: { data: newData.data, lastUpdatedDate: moment(), loading: false } }],
          });
        }
      },
      loadAlphaStatistics: async (force_skip: boolean = false) => {
        patchState(store, { ...store.alphaStatistics, alphaStatistics: { ...store.alphaStatistics(), loading: true } });

        if (!force_skip) {
          const existingData = GetStateData(store.alphaStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, configurationStore.skipCache().data);
          if (existingData) {
            patchState(store, { ...store.alphaStatistics, alphaStatistics: { ...store.alphaStatistics(), loading: false } });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientAlpha.alphaImportStatistics().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            ...store.alphaStatistics,
            alphaStatistics: {
              data: [],
              loading: false,
              lastUpdatedDate: undefined,
            },
          });
          return;
        }

        patchState(store, {
          ...store.alphaStatistics,
          alphaStatistics: {
            data: newData.data,
            loading: false,
            lastUpdatedDate: moment(),
          },
        });
      },
      syncDevPosAll: async (importTableType: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1) {
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
          return;
        }

        patchState(store, {
          sync: {
            ids: store.sync.ids(),
            all: [
              ...store.sync.all().slice(0, allIndex),
              { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
              ...store.sync.all().slice(allIndex + 1),
            ],
          },
        });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosDynamic.dynamicDevPosValidate(importTableType, undefined).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRow: async (importTableType: ImportTableTypeEnum, id: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosDynamic.dynamicDevPosValidate(importTableType, id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      syncAlphaAll: async (importTableType: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientAlpha.dynamicAlphaSync(importTableType, undefined).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncAlphaRow: async (importTableType: ImportTableTypeEnum, id: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientAlpha.dynamicAlphaSync(importTableType, id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      unsetSyncAll: (tableTypeEnum: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === tableTypeEnum);
        if (allIndex !== -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter((_, i) => i % 2 === 0),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },
      unsetSyncSingleRow: (tableTypeEnum: ImportTableTypeEnum, rowId: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === tableTypeEnum && x.data?.rowId === rowId);
        if (idIndex !== -1)
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter(x => x !== rowId),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },

      unsetPostRow: (tableTypeEnum: ImportTableTypeEnum, rowId: number | undefined, success: boolean) => {
        const idIndex = store.post.ids().findIndex(x => x.data?.table === tableTypeEnum && x.data?.rowId === rowId);
        if (idIndex !== -1)
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter(x => x !== rowId),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },

      syncDevPosAllInvoices: async () => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.validateAndPost().pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosAllPurchaseInvoices: async () => {
        const importTableType = ImportTableTypeEnum.DevPosPurchaseInvoice;
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosPurchaseInvoice.validateAndPostPurchaseInvoice().pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRowInvoice: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.validateAndPost(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },
      syncDevPosRowPurchaseInvoice: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosPurchaseInvoice;
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosPurchaseInvoice.validateAndPostPurchaseInvoice(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      postDevPosAllInvoices: async (batch: number) => {
        const translate = injector.get(TranslateService);
        const rowIds = await firstValueFrom(
          httpClientDevPosSiDynamic.postBatch(batch).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => (res.succeeded && res.data ? res.data : [])),
            tap(data => {
              if (data.length > 0) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (rowIds.length === 0) return;

        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;

        for (const id of rowIds) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          if (idIndex === -1)
            patchState(store, {
              post: {
                ids: [
                  ...store.post.ids(),
                  {
                    data: { table: importTableType, rowId: id },
                    loading: true,
                    lastUpdatedDate: moment(),
                  },
                ],
              },
            });
          else
            patchState(store, {
              post: {
                ids: [
                  ...store.post.ids().slice(0, idIndex),
                  { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                  ...store.post.ids().slice(idIndex + 1),
                ],
              },
            });
        }
      },

      postDevPosRowInvoice: async (id: number, generateTransferShipment?: boolean, generatePayment?: boolean) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
            },
          });
        else
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids().slice(0, idIndex),
                { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.post.ids().slice(idIndex + 1),
              ],
            },
          });

        // patchState(store, {
        //   post: {
        //     ids: [
        //       ...store.post.ids().slice(0, idIndex),
        //       { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
        //       ...store.post.ids().slice(idIndex + 1),
        //     ],
        //   },
        // });

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.postSingle(id, generateTransferShipment, generatePayment).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            // tap(res => {
            //   if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            // }),
          ),
        );

        if (!updated) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });
        }
      },
      postDevPosRowPurchaseInvoice: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosPurchaseInvoice;
        const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
            },
          });
        else
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids().slice(0, idIndex),
                { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.post.ids().slice(idIndex + 1),
              ],
            },
          });

        // patchState(store, {
        //   post: {
        //     ids: [
        //       ...store.post.ids().slice(0, idIndex),
        //       { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
        //       ...store.post.ids().slice(idIndex + 1),
        //     ],
        //   },
        // });

        const updated = await firstValueFrom(
          httpClientDevPosPurchaseInvoice.queuePostDevPosPurchaseInvoice(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
          ),
        );

        if (!updated) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });
        }
      },
      setIds: (tableTypeEnum: ImportTableTypeEnum, ids: number[]) => {
        const index = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (index === -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds(),
              {
                ImportTableType: tableTypeEnum,
                RowId: ids,
              },
            ],
          });
        else
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, index),
              {
                ImportTableType: tableTypeEnum,
                RowId: ids,
              },
              ...store.loadedIds().slice(index + 1),
            ],
          });
      },
      syncDevPosAllCashReceipt: async () => {
        const importTableType = ImportTableTypeEnum.DevPosCashReceipt;
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosCashReceiptDynamic.validateCashReceipt().pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRowCashReceipt: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosCashReceipt;
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosCashReceiptDynamic.validateCashReceipt(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },
      convertCashReceiptRow: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosCashReceipt;
        const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
            },
          });
        else
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids().slice(0, idIndex),
                { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.post.ids().slice(idIndex + 1),
              ],
            },
          });

        patchState(store, {
          post: {
            ids: [
              ...store.post.ids().slice(0, idIndex),
              { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
              ...store.post.ids().slice(idIndex + 1),
            ],
          },
        });

        const updated = await firstValueFrom(
          httpClientDevPosCashReceiptDynamic.convertCashReceipt(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            // tap(res => {
            //   if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            // }),
          ),
        );

        if (!updated) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });
        }
      },
      syncDevPosAllSaleOrders: async () => {
        const importTableType = ImportTableTypeEnum.DevPosCashReceipt;
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosSaleOrderDynamic.validateSaleOrder().pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRowSaleOrders: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesOrder;
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const translate = injector.get(TranslateService);

        const updated = await firstValueFrom(
          httpClientDevPosSaleOrderDynamic.validateSaleOrder(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },
      convertSaleOrderRow: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesOrder;
        const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
            },
          });
        else
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids().slice(0, idIndex),
                { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.post.ids().slice(idIndex + 1),
              ],
            },
          });

        patchState(store, {
          post: {
            ids: [
              ...store.post.ids().slice(0, idIndex),
              { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
              ...store.post.ids().slice(idIndex + 1),
            ],
          },
        });

        const updated = await firstValueFrom(
          httpClientDevPosSaleOrderDynamic.convertSaleOrder(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            // tap(res => {
            //   if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            // }),
          ),
        );

        if (!updated) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });
        }
      },
      clearDevPosCache: () => {
        patchState(store, INITIAL_DEVPOS_STATE);
      },
    }),
  ),
  withStorageSync({
    key: 'DEVPOS_STATE',
    autoSync: true,
  }),
  withDevtools('devPosStore'),
);
